<template>
  <div
    class="vx-row mb-4 py-2"
    style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc"
  >
    <div class="vx-col w-full">
      <vs-button
        size="small"
        v-on:click="deletePayment(index)"
        color="danger"
        icon-pack="feather"
        icon="icon-trash-2"
        :disabled="data.customer_order_payment_id != 0"
      />
    </div>
    <div class="vx-col w-full">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.payment_method_name }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <SelectPaymentMethod
        ref="selectPaymentMethod"
        @data="setSelectPaymentMethod"
      />
    </div>
    <div class="vx-col w-full">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.payment_value }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="data.new_payment_value"
        label="Payment Value"
        type="number"
        class="w-full"
        :min="0"
      />
    </div>
    <div class="vx-col w-full my-2">
      <label for="">Choose Reason:</label>
      <select-reason
        @data="setSelectReason"
        ref="selectReason"
        :reasonGroup="'AR-Outstanding'"
      />
    </div>
    <div class="vx-col w-full my-2">
      <vs-textarea v-model="data.notes" label="Notes" rows="3" />
    </div>
    <div class="vx-col w-1/2" v-show="onlyGiro">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.giro_number }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="data.new_giro_number"
        label="Giro Number"
        type="text"
        class="w-full"
      />
    </div>
    <div class="vx-col w-1/2" v-show="onlyGiro">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.giro_transfer_date }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="data.new_giro_transfer_date"
        label="Giro Transfer Date"
        type="date"
        class="w-full"
      />
    </div>
    <div class="vx-col w-full" v-show="onlyBankTransfer">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.date_transfer }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to: </label>
      <vs-input
        v-model="data.new_date_transfer"
        label="Bank Transfer Date"
        type="date"
        class="w-full"
      />
    </div>
    <div class="vx-col w-full" v-show="onlyGiroBankTransfer">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.bank_name }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <SelectBank @data="setSelectBank" ref="selectBank" />
    </div>
    <div class="vx-col w-1/2">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.deduction_value }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="data.new_deduction_value"
        label="Deduction Value"
        type="number"
        class="w-full"
        :max="999999999"
        :min="0"
      />
    </div>
    <div class="vx-col w-1/2">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current: {{ data.deduction_reason }}</label
      >
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="data.new_deduction_reason"
        label="Deduction Reason"
        type="text"
        class="w-full"
      />
    </div>
    <div class="vx-col w-full mt-2" v-show="onlyGiroBankTransfer">
      <label for="" class="font-bold" v-show="checkIsHaveOldData()"
        >Current:
        <a :href="data.photo_proof_of_payment" target="_blank">
          <vs-button
            size="small"
            color="success"
            icon-pack="feather"
            icon="icon-eye"
          ></vs-button>
        </a>
      </label>
      <br />
      <label for="" v-show="checkIsHaveOldData()">Change to:</label>
      <vs-input
        v-model="emptyString"
        label="Upload Photo Proof of Payment"
        type="file"
        class="w-full"
        @change="onFileChange($event, data)"
      />
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import SelectPaymentMethod from "@/components/master/SelectPaymentMethod.vue";
import SelectBank from "@/components/master/SelectBank.vue";
import SelectReason from "@/components/master/SelectReason.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.oldData = JSON.stringify(this.data);
    this.setIfAlreadyHaveData();
  },
  data() {
    return {
      oldData: {},
      emptyString: "",
      onlyGiro: false,
      onlyCash: false,
      onlyBankTransfer: false,
      onlyGiroBankTransfer: false,
    };
  },
  components: { SelectPaymentMethod, SelectBank, SelectReason },
  methods: {
    setSelectReason(value) {
      this.data.new_reason_id = value.ID;
      this.data.new_reason_name = value.reason;
    },
    setIfAlreadyHaveData() {
      //  check if payment method not null
      if (this.data.new_payment_method_id != 0) {
        this.setSelectPaymentMethod({
          id: this.data.new_payment_method.id,
          code: this.data.new_payment_method.code,
          nanem: this.data.new_payment_method.name,
        });

        this.$refs.selectPaymentMethod.setSelected(
          this.data.new_payment_method.id,
          this.data.new_payment_method.code,
          this.data.new_payment_method.name
        );
      }

      // check if bank not null
      if (this.data.new_bank_id != 0) {
        this.$refs.selectBank.setSelected(
          this.data.new_bank_id,
          this.data.new_bank_name
        );
      }
    },
    onFileChange(event, tr) {
      const file = event.target.files[0];
      tr.temp_file = file.name;
      const reader = new FileReader();
      if (typeof file !== "undefined") {
        reader.readAsDataURL(file);
        reader.onload = () => {
          // remove from base64,
          let result = reader.result.split(",")[1];
          tr.new_photo_proof_of_payment = result;
        };
      }
    },
    setSelectBank(value) {
      this.data.new_bank_id = value.id;
      this.data.new_bank_name = value.name;
      this.data.new_bank = {
        id: value.id,
        name: value.name,
        code: value.code,
      };
      if (this.data.new_payment_method_name == "Giro") {
        this.data.new_giro_transfer_bank_id = value.id;
      }
    },
    deletePayment(index) {
      if (this.data.customer_order_payment_id == 0) {
        this.$emit("deletePayment", index);
      }
    },
    async setSelectPaymentMethod(value) {
      const types = value.code;
      if (types == "GR") {
        this.onlyGiro = true;
        this.onlyCash = false;
        this.onlyBankTransfer = false;
        this.onlyGiroBankTransfer = true;
      } else if (types == "TRF") {
        this.onlyGiro = false;
        this.onlyCash = false;
        this.onlyBankTransfer = true;
        this.onlyGiroBankTransfer = true;
      } else {
        this.onlyGiro = false;
        this.onlyCash = true;
        this.onlyBankTransfer = false;
        this.onlyGiroBankTransfer = false;
      }

      // set data
      this.data.new_payment_method_id = value.id;
      this.data.new_payment_method_name = value.name;
      this.data.new_payment_method = {
        id: value.id,
        code: value.code,
        name: value.name,
      };
    },
    checkIsHaveOldData() {
      if (this.data.isNew) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        // triger if data changed
        if (this.oldData != JSON.stringify(val)) {
          this.$emit("isChangeData", true);
        }
      },
      deep: true,
    },
  },
};
</script>
